import gsap from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const fixedCTAs = document.querySelectorAll('.fixed-corner-cta_component');

  const showCTA = () => {
    fixedCTAs.forEach((cta) => {
      cta.classList.remove('is-invisble');
    });
  };

  const hideCTA = () => {
    fixedCTAs.forEach((cta) => {
      cta.classList.add('is-invisble');
    });
  };

  ScrollTrigger.create({
    trigger: 'body',
    start: '1000px top',
    end: 'bottom-=250px bottom',
    markers: false,
    onToggle: (self) => {
      self.isActive === true ? showCTA() : hideCTA();
    },
  });
};
