import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default () => {
  tippy('#nav-login', {
    content: 'Bald verfügbar',
  });

  tippy('#nav-login-mobile', {
    content: 'Bald verfügbar',
  });

  tippy('#nav-lang', {
    content: 'Coming soon',
  });

  tippy('#nav-lang-mobile', {
    content: 'Coming soon',
  });
};
