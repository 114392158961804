import Flickity from 'flickity';
import 'flickity/dist/flickity.min.css';

export default () => {
  const stepSlider = new Flickity('[step-slider="slider"]', {
    cellAlign: 'left',
    contain: true,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: false,
    draggable: true,
    adaptiveHeight: false,
  });

  document.querySelectorAll('[step-slider="slider-right"]').forEach((button) => {
    button.addEventListener('click', () => {
      stepSlider.next();
    });
  });

  document.querySelectorAll('[step-slider="slider-left"]').forEach((button) => {
    button.addEventListener('click', () => {
      stepSlider.previous();
    });
  });
};
