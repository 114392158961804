import Flickity from 'flickity';
import 'flickity/dist/flickity.min.css';

export default () => {
  document.querySelectorAll('.testimonial_slider').forEach((slider) => {
    slider.classList.remove('w-row');
  });

  document.querySelectorAll('.testimonial_slide').forEach((slider) => {
    slider.classList.remove('w-col');
    slider.classList.remove('w-col-4');
  });

  const testimonialSliderOptions = {
    cellAlign: 'center',
    contain: true,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: false,
    draggable: true,
    adaptiveHeight: false,
  };

  if (matchMedia('screen and (min-width: 992px)').matches) {
    testimonialSliderOptions.draggable = false;
  }

  const testimonialSlider = new Flickity('.testimonial_slider', testimonialSliderOptions);

  document.querySelectorAll('.testimonial_slider-arrow.right').forEach((button) => {
    button.addEventListener('click', () => {
      testimonialSlider.next(true);
    });
  });

  document.querySelectorAll('.testimonial_slider-arrow.left').forEach((button) => {
    button.addEventListener('click', () => {
      testimonialSlider.previous(true);
    });
  });
};
