import TestimonialSlider from '../Components/Slider/TestimonialSlider';
import StepsSlider from '../Components/Slider/StepsSlider';
import WordChangeAnimation from '../Components/Animation/WordChangeAnimation';
import StepsAnimation from '../Components/Animation/StepsAnimation';

export default () => {
  TestimonialSlider();
  StepsSlider();
  StepsAnimation();
  // WordChangeAnimation();
};
