import { createApp } from 'petite-vue';

export default () => {
  createApp({
    nestorCount: 1,
    buyMode: 'monthly',
    buyModeHandle: '5 Jahre',
    buyModeTerminology: 'pro Monat',
    priceMonthlyBase: 495,
    priceMonthlyArray: [525, 435, 365],
    priceYearIndex: 2,
    priceYearlyBase: 445,
    showPopup: false,

    calculatedPrice() {
      return `${(this.nestorCount * this.priceMonthlyArray[this.priceYearIndex]).toFixed(2)}€`;
    },

    updateCount(event) {
      this.nestorCount = event.target.value;
    },

    popupToggle(state) {
      if (state == 'close') {
        this.showPopup = false;
      }

      if (state == 'open') {
        this.showPopup = true;
      }
    },

    updateYearIndex(index, element) {
      this.priceYearIndex = index;
      this.buyModeHandle = element.innerText;
      $('.w-dropdown').trigger('w-close');
    },

    updateBuyMode(mode, handle) {
      this.buyMode = mode;
      this.buyModeHandle = handle;
      $('.w-dropdown').trigger('w-close');
    },

    nestorCountHandle() {
      $('.w-dropdown').trigger('w-close');
      return `${this.nestorCount} Nestor`;
    },
  }).mount('#price-component');
};
